<template>
  <b-card-code title="Edit inventory/website">
    <b-overlay 
      :show="show" 
      rounded="sm" 
      no-fade 
    >
        <div :aria-hidden="show ? 'true' : null">
            <b-form 
                ref="form"
                class="mt-1"
                @submit.prevent="EditInventoryForm"
            >
                <b-row>
                    <b-col md="9">
                        <b-row>
                            <!-- Inventory Type -->
                            <b-col md="12">
                                <b-form-group
                                    label="Inventory Type"
                                    label-for="inventory_type"
                                >
                                    <v-select
                                        v-model="inventory_type"
                                        :reduce="(option) => option.id"
                                        :options="inventories"
                                        @input="inventory_input"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- Site Domain -->
                            <b-col md="6">
                                <b-form-group
                                    label="Site Domain"
                                    label-for="inventory_domain"
                                >
                                    <b-input-group>            
                                        <b-form-input 
                                            placeholder="example.com" 
                                            v-model="inventory_domain"
                                            v-on:keyup="inventoryDomain"
                                            :state="validDomain(inventory_domain)"
                                            :class="validDomain(inventory_domain)==false?'text-danger':'text-success'"
                                            @input="inventory_domain_input"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- Inventory Name -->
                            <b-col md="6">
                                <b-form-group
                                    label="Inventory Name"
                                    label-for="inventory_name"
                                >
                                    <b-input-group>            
                                    <b-form-input 
                                        placeholder="Inventory Name" 
                                        name="inventory_name" 
                                        v-model="inventory_name"
                                        :class="validDomain(inventory_domain)==false?'text-danger':'text-success'"
                                        @input="inventory_name_input"
                                    />
                                    <b-input-group-append is-text>
                                        <b-form-checkbox
                                            v-model="inventory_status"
                                            :checked="inventory_status"
                                            switch
                                            inline
                                            @input="inventory_status_input"
                                        >
                                        </b-form-checkbox>
                                    </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- IAB Categories -->
                            <b-col md="6">
                            <b-form-group
                                label="IAB Categories"
                                label-for="slot_iab_categories"
                            >
                                <v-select
                                    :reduce="(option) => option.name"
                                    :options="iab_categories_list"
                                    v-model="slot_iab_categories"
                                    multiple
                                    :selectable="() => slot_iab_categories.length < 6"
                                    @input="updateIABCats"
                                />
                            </b-form-group>
                            </b-col>

                            <!-- IAB Sub Categories -->
                            <b-col md="6">
                            <b-form-group
                                label="IAB Sub Categories"
                                label-for="slot_iab_sub_categories"
                            >              
                                <v-select
                                    :reduce="(option) => option.name"
                                    :options="iab_sub_categories_list"
                                    v-model="slot_iab_sub_categories"
                                    multiple
                                    :selectable="() => slot_iab_sub_categories.length < 12"
                                    @input="iab_sub_categories_input"
                                >
                                </v-select>
                            </b-form-group>
                            </b-col>

                            <!-- Content Language-->
                            <b-col md="6">
                            <b-form-group
                                label="Content Language"
                                label-for="language"
                            >
                                <v-select
                                    :reduce="(option) => option.code"
                                    :options="languages_list"
                                    v-model="language"
                                    @input="language_input"
                                />
                            </b-form-group>
                            </b-col>

                            <b-col md="12"></b-col> 
                            <!--  -->

                            <b-col md="12"> 
                                <b-card-code title="More Options">
                                    <app-collapse>

                                        <app-collapse-item title="Privacy Options">
                                            <b-row>
                                                <b-col md="6">

                                                    <b-col cols="12" class="mb-1">
                                                        <b-form-checkbox
                                                            id="mobile_optimized"
                                                            :checked="mobile_optimized"
                                                            switch
                                                            inline
                                                            @input="mobile_optimized_input"
                                                        >
                                                            <span>Mobile Optimized Layout</span>
                                                        </b-form-checkbox>
                                                    </b-col>

                                                    <b-col cols="12" class="mb-1">
                                                        <b-form-checkbox
                                                            id="gdpr"
                                                            v-model="gdpr"
                                                            :checked="gdpr"
                                                            switch
                                                            inline
                                                            @input="gdpr_input"
                                                        >
                                                            <span>GDPR compliant</span>
                                                        </b-form-checkbox>
                                                    </b-col>

                                                    <b-col cols="12" class="mb-1">
                                                        <b-form-checkbox
                                                            id="coppa"
                                                            v-model="coppa"
                                                            :checked="coppa"
                                                            switch
                                                            inline
                                                            @input="coppa_input"
                                                        >
                                                            <span>Coppa</span>
                                                        </b-form-checkbox>  
                                                    </b-col>                                          

                                                </b-col> 
                                                <b-col md="6">

                                                    <!-- Privacy Policy URL -->
                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="Privacy Policy URL"
                                                            label-for="privacy"
                                                        >
                                                            <b-input-group>            
                                                                <b-form-input 
                                                                placeholder="http://example.com/privacy-policy" 
                                                                v-model="privacy"
                                                                @input="privacy_input"
                                                                />
                                                            </b-input-group>
                                                        </b-form-group>
                                                    </b-col>

                                                </b-col> 
                                            </b-row>                                    
                                        </app-collapse-item>

                                        <app-collapse-item title="Description">
                                            <b-row>
                                                <b-col class="12">
                                                    <!-- Inventory Description -->
                                                    <b-form-textarea
                                                        label="Description"
                                                        v-model="inventory_description"
                                                        placeholder="Description"
                                                        rows="3"
                                                        :state="inventory_description.length <= maxChar"
                                                        class="char-textarea"
                                                        :class="inventory_description.length >= maxChar ? 'text-danger' : ''"
                                                        @input="inventory_description_input"
                                                    />
                                                    <small
                                                        class="textarea-counter-inventory_description float-right"
                                                        :class="inventory_description.length >= maxChar ? 'bg-danger' : ''"
                                                    >
                                                        <span class="char-count">{{ inventory_description.length }}</span> / {{ maxChar }}
                                                    </small>

                                                </b-col>
                                            </b-row>
                                        </app-collapse-item>

                                    </app-collapse>
                                </b-card-code>
                            </b-col> 

                            <b-col cols="6"></b-col>

                            <!-- submit and reset -->
                            <b-col cols="12" class="my-5 text-center">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="submit"
                                variant="primary"
                                class="mr-1"
                            >
                                Update
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="reset"
                                variant="outline-secondary"
                                @click="reset"
                            >
                                Reset
                            </b-button>
                            </b-col>
                        </b-row>

                    </b-col>
                </b-row>
            </b-form>
        </div>
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BRow,
  BCol,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormValidFeedback,
  BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormInvalidFeedback,
    BFormValidFeedback,
    vSelect,
    BFormTextarea,
    AppCollapse,
    AppCollapseItem
  },
  directives: {
    Ripple
  },
  data() {
    return {
      inventory_type:{label:'Website',id:0},
      inventories: [
        {label :'Website',id:0}, 
        {label :'Android App',id:1}, 
        {label :'iOS App',id:2}
      ],
      inventory_domain:'',
      inventory_name:'',
      inventory_status:true,
      slot_iab_categories:'',
      slot_iab_sub_categories:'',
      language:'',
      iab_categories_list:[],
      iab_sub_categories_list:[],
      languages_list:[],
      mobile_optimized:true,
      gdpr:true,
      coppa:true,
      privacy:'',
      inventory_description:'',
      maxChar: 500,
      ad_slot_id: 1,
      edited_data:{},
      // form submission process handle
      show: false,
      interval: null
    }
  },
  methods: {
    reset(){
        console.log('testing reset');
    },
    inventory_input(id){
        this.edited_data.inventory_type=id;
    },
    inventory_domain_input(name){
        this.edited_data.inventory_domain=name;
        this.edited_data.inventory_name=this.inventory_name;
    },
    inventory_name_input(name){
        this.edited_data.inventory_name=name;
        this.edited_data.inventory_domain=this.inventory_domain;
    },
    inventory_status_input(check){
        this.edited_data.inventory_status=check;
    },
    iab_sub_categories_input(names){
        this.edited_data.slot_iab_sub_categories=names.join(',');
    },
    language_input(code){
        this.edited_data.language=code;
    },
    mobile_optimized_input(check){
        this.edited_data.mobile_optimized=check;
    },
    gdpr_input(check){
        this.edited_data.gdpr=check;
    },
    coppa_input(check){
        this.edited_data.coppa=check;
    },
    privacy_input(data){
        this.edited_data.privacy=data;
    },
    inventory_description_input(data){
        this.edited_data.inventory_description=data;
    },
    inventoryDomain(){
        this.inventory_name=this.inventory_domain.replace(/(^\w+:|^)\/\//, '');
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    EditInventoryForm(){
        this.show = true;
        this.interval = setInterval(() => {
            const accessToken = useJwt.getToken('accessToken');
            
            this.$http.post('https://api.purplepatch.online/publisher/inventory/edit/'+router.currentRoute.params.id, this.edited_data, { 
                headers: {
                    Authorization:'Bearer ' + accessToken,
                    'x-access-token': accessToken 
                } 
            })
            .then(response => {
                this.show = false;
                this.clearInterval();
                // console.log(response);
                if( response.status == 201 ){
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: response.data.msg,
                            icon: 'AwardIcon',
                            variant: 'success',
                            text: response.data.msg,
                        },
                    });
                    return router.push({ name: 'inventory' });
                }
            })
            .catch(error => {
                this.show = false;
                this.clearInterval();
                if( error.status != 200 ){
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: error,
                            icon: 'AlertOctagonIcon',
                            variant: 'error',
                            text: error,
                        },
                    });
                }
            })
        }, 350);
    },
    validDomain(domain){
        return (/^(?:(http:\/\/|https:\/\/))?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9\/]{2,})+$/.test(domain)) ? true : false;
    },
    
    updateIABCats(data){
        this.edited_data.slot_iab_categories=data.join(',');
        const accessToken = useJwt.getToken('accessToken')
        this.$http.post('https://api.purplepatch.online/getiabsubcategories', {'iab_categories':data}, {
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            }
        })
      .then(res => {            
            if(res.data){                
                this.iabsubdisabled=false;
                this.iab_sub_categories_list=res.data;
            }
        })
    }
  },
  created() {
        const accessToken = useJwt.getToken('accessToken')
        this.$http.get('https://api.purplepatch.online/publisher/inventory/edit/'+router.currentRoute.params.id, { 
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            } 
        })
      .then(response => { 
        let data = response.data;
        this.inventory_type             = data.inventory_type_list;
        this.inventory_status           = data.inventory_status ==1?true:false;
        this.iab_categories_list        = data.iab_categories_list;
        this.iab_sub_categories_list    = data.iab_sub_categories_list;
        this.languages_list             = data.languages_list;
        this.language                   = data.language_data;
        this.inventory_domain           = data.inventory_domain;
        this.inventory_name             = data.inventory_name;
        this.slot_iab_categories        = data.iab_categories_data;
        this.slot_iab_sub_categories    = data.iab_sub_categories_data;
        this.mobile_optimized           = data.mobile_optimized==1?true:false;
        this.gdpr                       = data.gdpr==1?true:false;
        this.coppa                      = data.coppa==1?true:false;
        this.privacy                    = data.privacy;
        this.inventory_description      = data.inventory_description;
        console.log(data);
      })
      .catch(error => {
        // if (error.response.status === 404) {
            console.log('error');
            console.log(error);
        // }
      })

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>